<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";

import "prismjs";
import "prismjs/themes/prism.css";

export default {
  data() {
    return {
      title: "Progress",
      items: [
        {
          text: "Base UI",
          href: "/",
        },
        {
          text: "Progress",
          active: true,
        },
      ],
    };
  },
  name: "Progress",
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    const checkbox = document.getElementsByClassName("code-switcher");
    Array.from(checkbox).forEach((check) => {
      check.addEventListener("change", () => {
        const card = check.closest(".card");
        const preview = card.querySelector(".live-preview");
        const code = card.querySelector(".code-view");
        if (check.checked) {
          // do this
          preview.classList.add("d-none");
          code.classList.remove("d-none");
        } else {
          // do that
          preview.classList.remove("d-none");
          code.classList.add("d-none");
        }
      });
    });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xxl-6">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Default Progress</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label for="default" class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="default"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted">
              Use
              <code>progress</code> class to show default progress.
            </p>

            <div class="live-preview">
              <div class="progress bg-soft-primary mb-4">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-primary mb-4">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 25%"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-primary mb-4">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 50%"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-primary mb-4">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 75%"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-primary">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 100%"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup" style="max-height:  275px">
<code>&lt;!-- Base Examples --&gt;
    &lt;b-progress :value=&quot;0&quot; class=&quot;bg-soft-primary mb-4&quot;&gt;&lt;/b-progress&gt;

    &lt;b-progress :value=&quot;25&quot; class=&quot;bg-soft-primary mb-4&quot;&gt;&lt;/b-progress&gt;

    &lt;b-progress :value=&quot;50&quot; class=&quot;bg-soft-primary mb-4&quot;&gt;&lt;/b-progress&gt;

    &lt;b-progress :value=&quot;75&quot; class=&quot;bg-soft-primary mb-4&quot;&gt;&lt;/b-progress&gt;
    
    &lt;b-progress :value=&quot;100&quot;class=&quot;bg-soft-primary&quot;&gt;
</code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-xxl-6">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">
              Progress with background color
            </h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label for="background-show-code" class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="background-show-code"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted">
              Use
              <code>bg-</code> class to progress bar class with the
              below-mentioned color variation to set the background color
              progress bar.
            </p>

            <div class="live-preview">
              <div class="progress bg-soft-primary mb-4">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  style="width: 15%"
                  aria-valuenow="15"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-success mb-4">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 25%"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-info mb-4">
                <div
                  class="progress-bar bg-info"
                  role="progressbar"
                  style="width: 50%"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-warning mb-4">
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  style="width: 75%"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-danger">
                <div
                  class="progress-bar bg-danger"
                  role="progressbar"
                  style="width: 100%"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup" style="max-height:  275px">
<code>&lt;!-- Backgrounds --&gt;
    &lt;b-progress :value=&quot;15&quot; variant=&quot;primary&quot; class=&quot;mb-4&quot;&gt;&lt;/b-progress&gt;

    &lt;b-progress :value=&quot;25&quot; variant=&quot;success&quot; class=&quot;mb-4&quot;&gt;&lt;/b-progress&gt;

    &lt;b-progress :value=&quot;50&quot; variant=&quot;info&quot; class=&quot;mb-4&quot;&gt;&lt;/b-progress&gt;

    &lt;b-progress :value=&quot;75&quot; variant=&quot;warning&quot; class=&quot;mb-4&quot;&gt;&lt;/b-progress&gt;

    &lt;b-progress :value=&quot;100&quot; variant=&quot;danger&quot; class=&quot;mb-4&quot;&gt;&lt;/b-progress&gt;

    </code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-xxl-6">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Progress with Label</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label
                  for="labelprogress-showcode"
                  class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="labelprogress-showcode"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted">
              Add labels to your progress bars by placing text within the
              progress-bar.
            </p>

            <div class="live-preview">
              <div class="progress bg-soft-primary">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 25%"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  25%
                </div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup">
<code>&lt;!-- Labels Example --&gt;
    &lt;b-progress :value=&quot;25&quot; variant=&quot;primary&quot; class=&quot;mb-4&quot; show-value &gt;&lt;/b-progress&gt;
</code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-xxl-6">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Multiple Bars</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label
                  for="multibarprogress-showcode"
                  class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="multibarprogress-showcode"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted">
              Use
              <code>bg-</code> class to progress bar class with the
              below-mentioned color to change the appearance of individual
              progress bars.
            </p>

            <div class="live-preview">
              <div class="progress bg-soft-primary">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 15%"
                  aria-valuenow="15"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 30%"
                  aria-valuenow="30"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  class="progress-bar bg-info"
                  role="progressbar"
                  style="width: 20%"
                  aria-valuenow="20"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup">
<code>&lt;!-- Multiple Bars --&gt;
&lt;b-progress&gt;
    &lt;b-progress :value=&quot;15&quot; variant=&quot;primary&quot; /&gt;
    &lt;b-progress :value=&quot;30&quot; variant=&quot;success&quot; /&gt;
    &lt;b-progress :value=&quot;20&quot; variant=&quot;info&quot; /&gt;
&lt;/b-progress&gt;</code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-xxl-6">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Height</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label
                  for="heightprogress-showcode"
                  class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="heightprogress-showcode"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted">
              Use
              <code>progress-sm</code>, <code>progress-lg</code>, or
              <code>progress-xl</code> class to set the different heights of
              progress.
            </p>
            <div class="live-preview">
              <div class="mb-4">
                <h5 class="fs-13">Small Progress</h5>
                <!-- Prgress sm -->
                <div class="progress bg-soft-primary progress-sm">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: 25%"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div class="mb-4">
                <h5 class="fs-13">Default Progress</h5>
                <!-- Prgress md -->
                <div class="progress bg-soft-success">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 40%"
                    aria-valuenow="40"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div class="mb-4">
                <h5 class="fs-13">Large Progress</h5>
                <!-- Prgress lg -->
                <div class="progress bg-soft-warning progress-lg">
                  <div
                    class="progress-bar bg-warning"
                    role="progressbar"
                    style="width: 50%"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div>
                <h5 class="fs-13">Extra Large Progress</h5>
                <!-- Prgress xl -->
                <div class="progress bg-soft-danger progress-xl">
                  <div
                    class="progress-bar bg-danger"
                    role="progressbar"
                    style="width: 70%"
                    aria-valuenow="70"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup" style="max-height:  275px">
<code>&lt;!-- Prgress sm --&gt;
    &lt;b-progress :value=&quot;25&quot; class=&quot;progress-sm&quot; variant=&quot;primary&quot;&gt;&lt;/b-progress&gt;

    &lt;b-progress :value=&quot;40&quot; variant=&quot;success&quot;&gt;&lt;/b-progress&gt;

    &lt;b-progress :value=&quot;50&quot; class=&quot;progress-lg&quot; variant=&quot;warning&quot;&gt;&lt;/b-progress&gt;

    &lt;b-progress :value=&quot;70&quot; class=&quot;progress-xl&quot; variant=&quot;danger&quot;&gt;&lt;/b-progress&gt;
</code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-xxl-6">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Striped Progress</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label
                  for="stripesprogress-showcode"
                  class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="stripesprogress-showcode"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted">
              Use
              <code>progress-bar-striped</code> class to add strip to the
              progress.
            </p>
            <div class="live-preview">
              <div class="progress bg-soft-primary mb-4">
                <div
                  class="progress-bar progress-bar-striped"
                  role="progressbar"
                  style="width: 25%"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-success">
                <div
                  class="progress-bar progress-bar-striped bg-success"
                  role="progressbar"
                  style="width: 40%"
                  aria-valuenow="40"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup">
<code>&lt;!-- Striped Prgress --&gt;
    &lt;b-progress striped :value=&quot;25&quot; variant=&quot;primary&quot;&gt;&lt;/b-progress&gt;

    &lt;b-progress striped :value=&quot;40&quot; variant=&quot;success&quot;&gt;&lt;/b-progress&gt;
</code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">
              Animated Striped Progress
            </h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label
                  for="animationstripesprogress-showcode"
                  class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="animationstripesprogress-showcode"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted">
              Use
              <code>progress-bar-striped progress-bar-animated</code> class to
              add strip with animation to the progress.
            </p>

            <div class="live-preview">
              <div class="progress bg-soft-primary">
                <div
                  class="
                    progress-bar progress-bar-striped progress-bar-animated
                  "
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 75%"
                ></div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup">
<code>&lt;b-progress striped animated :value=&quot;75&quot; variant=&quot;primary&quot;&gt;&lt;/b-progress&gt;</code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-xxl-6">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Gradient Progress</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label
                  for="gradientprogress-showcode"
                  class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="gradientprogress-showcode"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted">
              Use
              <code>bg-gradient</code> class to show gradient progress bar.
            </p>

            <div class="live-preview">
              <div class="progress bg-soft-primary mb-4">
                <div
                  class="progress-bar bg-primary bg-gradient"
                  role="progressbar"
                  style="width: 15%"
                  aria-valuenow="15"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-success mb-4">
                <div
                  class="progress-bar bg-success bg-gradient"
                  role="progressbar"
                  style="width: 25%"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-info mb-4">
                <div
                  class="progress-bar bg-info bg-gradient"
                  role="progressbar"
                  style="width: 50%"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-warning mb-4">
                <div
                  class="progress-bar bg-warning bg-gradient"
                  role="progressbar"
                  style="width: 75%"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-danger">
                <div
                  class="progress-bar bg-danger bg-gradient"
                  role="progressbar"
                  style="width: 100%"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup">
<code>
&lt;b-progress :value=&quot;15&quot;  class=&quot;bg-gradient&quot; variant=&quot;primary&quot;&gt;&lt;/b-progress&gt;

&lt;b-progress :value=&quot;25&quot;  class=&quot;bg-gradient&quot; variant=&quot;success&quot;&gt;&lt;/b-progress&gt;

&lt;b-progress :value=&quot;50&quot;  class=&quot;bg-gradient&quot; variant=&quot;info&quot;&gt;&lt;/b-progress&gt;

&lt;b-progress :value=&quot;75&quot;  class=&quot;bg-gradient&quot; variant=&quot;warning&quot;&gt;&lt;/b-progress&gt;

&lt;b-progress :value=&quot;100&quot; class=&quot;bg-gradient&quot;  variant=&quot;danger&quot;&gt;&lt;/b-progress&gt;
    </code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
      <div class="col-xxl-6">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Animated Progress</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label
                  for="animatedprogress-showcode"
                  class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="animatedprogress-showcode"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted">
              Use
              <code>animated-progess</code> class to show progress with
              animation.
            </p>
            <div class="live-preview">
              <div class="progress bg-soft-primary animated-progess mb-4">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  style="width: 15%"
                  aria-valuenow="15"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-success animated-progess mb-4">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 25%"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-info animated-progess mb-4">
                <div
                  class="progress-bar bg-info"
                  role="progressbar"
                  style="width: 50%"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-warning animated-progess mb-4">
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  style="width: 75%"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress bg-soft-danger animated-progess">
                <div
                  class="progress-bar bg-danger"
                  role="progressbar"
                  style="width: 100%"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup">
<code>
&lt;b-progress animated :value=&quot;15&quot; variant=&quot;primary&quot;&gt;&lt;/b-progress&gt;

&lt;b-progress animated :value=&quot;25&quot; variant=&quot;success&quot;&gt;&lt;/b-progress&gt;

&lt;b-progress animated :value=&quot;50&quot; variant=&quot;info&quot;&gt;&lt;/b-progress&gt;

&lt;b-progress animated :value=&quot;75&quot; variant=&quot;warning&quot;&gt;&lt;/b-progress&gt;

&lt;b-progress animated :value=&quot;100&quot; variant=&quot;danger&quot;&gt;&lt;/b-progress&gt;
</code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-xxl-6">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Custom Progress</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label
                  for="customprogress-showcode"
                  class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="customprogress-showcode"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted">
              Use
              <code>animated-progess custom-progess</code> class to show custom
              progress with animation.
            </p>
            <div class="live-preview">
              <div class="progress animated-progess custom-progress mb-4">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  style="width: 15%"
                  aria-valuenow="15"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress animated-progess custom-progress mb-4">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style="width: 25%"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress animated-progess custom-progress mb-4">
                <div
                  class="progress-bar bg-info"
                  role="progressbar"
                  style="width: 50%"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress animated-progess custom-progress mb-4">
                <div
                  class="progress-bar bg-warning"
                  role="progressbar"
                  style="width: 75%"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="progress animated-progess custom-progress">
                <div
                  class="progress-bar bg-danger"
                  role="progressbar"
                  style="width: 100%"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup">
<code>
&lt;b-progress animated :value=&quot;15&quot; class=&quot;custom-progress mb-4&quot; variant=&quot;primary&quot;&gt;&lt;/b-progress&gt;

&lt;b-progress animated :value=&quot;25&quot; class=&quot;custom-progress mb-4&quot; variant=&quot;success&quot;&gt;&lt;/b-progress&gt;

&lt;b-progress animated :value=&quot;50&quot; class=&quot;custom-progress mb-4&quot; variant=&quot;info&quot;&gt;&lt;/b-progress&gt;

&lt;b-progress animated :value=&quot;75&quot; class=&quot;custom-progress mb-4&quot; variant=&quot;warning&quot;&gt;&lt;/b-progress&gt;

&lt;b-progress animated :value=&quot;100&quot; class=&quot;custom-progress&quot; variant=&quot;danger&quot;&gt;&lt;/b-progress&gt;
</code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-xxl-6">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">
              Custom Progress with Label
            </h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label
                  for="customprogresslabel-showcode"
                  class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="customprogresslabel-showcode"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted">
              Use
              <code>animated-progess custom-progess progress-label</code> class
              to show custom progress with animation and label.
            </p>
            <div class="live-preview">
              <div class="d-flex align-items-center pb-2 mt-4">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <div
                      class="
                        avatar-title
                        bg-light
                        rounded-circle
                        text-muted
                        fs-16
                      "
                    >
                      <i class="mdi mdi-facebook"></i>
                    </div>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div
                    class="
                      progress
                      animated-progess
                      custom-progress
                      progress-label
                    "
                  >
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      style="width: 15%"
                      aria-valuenow="15"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div class="label">15%</div>
                    </div>
                  </div>
                  
                </div>
              </div>

              <div class="d-flex align-items-center py-2">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <div
                      class="
                        avatar-title
                        bg-light
                        rounded-circle
                        text-muted
                        fs-16
                      "
                    >
                      <i class="mdi mdi-twitter"></i>
                    </div>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div
                    class="
                      progress
                      animated-progess
                      custom-progress
                      progress-label
                    "
                  >
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      style="width: 25%"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div class="label">25%</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center py-2">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar-xs">
                    <div
                      class="
                        avatar-title
                        bg-light
                        rounded-circle
                        text-muted
                        fs-16
                      "
                    >
                      <i class="mdi mdi-github"></i>
                    </div>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div
                    class="
                      progress
                      animated-progess
                      custom-progress
                      progress-label
                    "
                  >
                    <div
                      class="progress-bar bg-info"
                      role="progressbar"
                      style="width: 50%"
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div class="label">30%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup" style="max-height:  275px">
<code>&lt;div class=&quot;d-flex align-items-center pb-2&quot;&gt;
    &lt;div class=&quot;flex-shrink-0 me-3&quot;&gt;
        &lt;div class=&quot;avatar-xs&quot;&gt;
            &lt;div class=&quot;avatar-title bg-light rounded-circle text-muted fs-16&quot;&gt;
                &lt;i class=&quot;mdi mdi-facebook&quot;&gt;&lt;/i&gt;
            &lt;/div&gt;
        &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;flex-grow-1&quot;&gt;
&lt;b-progress animated :value=&quot;15&quot; class=&quot;custom-progress progress-label&quot; variant=&quot;primary&quot;&gt;15%&lt;/b-progress&gt;
    &lt;/div&gt;
&lt;/div&gt;</code>

<code>&lt;div class=&quot;d-flex align-items-center py-2&quot;&gt;
    &lt;div class=&quot;flex-shrink-0 me-3&quot;&gt;
        &lt;div class=&quot;avatar-xs&quot;&gt;
            &lt;div class=&quot;avatar-title bg-light rounded-circle text-muted fs-16&quot;&gt;
                &lt;i class=&quot;mdi mdi-twitter&quot;&gt;&lt;/i&gt;
            &lt;/div&gt;
        &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;flex-grow-1&quot;&gt;
&lt;b-progress animated :value=&quot;25&quot; class=&quot;custom-progress progress-label&quot; variant=&quot;success&quot;&gt;25%&lt;/b-progress&gt;
    &lt;/div&gt;
&lt;/div&gt;</code>

<code>&lt;div class=&quot;d-flex align-items-center py-2&quot;&gt;
    &lt;div class=&quot;flex-shrink-0 me-3&quot;&gt;
        &lt;div class=&quot;avatar-xs&quot;&gt;
            &lt;div class=&quot;avatar-title bg-light rounded-circle text-muted fs-16&quot;&gt;
                &lt;i class=&quot;mdi mdi-github&quot;&gt;&lt;/i&gt;
            &lt;/div&gt;
        &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;flex-grow-1&quot;&gt;
&lt;b-progress animated :value=&quot;50&quot; class=&quot;custom-progress progress-label&quot; variant=&quot;info&quot;&gt;50%&lt;/b-progress&gt;
    &lt;/div&gt;
&lt;/div&gt;</code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-xxl-6">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Content Progress</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label
                  for="bsprogrescontent-showcode"
                  class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="bsprogrescontent-showcode"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted pb-2">
              Example of progress with content wrapped in progress.
            </p>

            <div class="live-preview">
              <div class="card bg-light overflow-hidden shadow-none">
                <div class="card-body">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h6 class="mb-0">
                        <b class="text-secondary">30%</b> Update in progress...
                      </h6>
                    </div>
                    <div class="flex-shrink-0">
                      <h6 class="mb-0">1 min left</h6>
                    </div>
                  </div>
                </div>
                <div class="progress bg-soft-secondary rounded-0">
                  <div
                    class="progress-bar bg-secondary"
                    role="progressbar"
                    style="width: 30%"
                    aria-valuenow="30"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div class="card bg-light overflow-hidden shadow-none">
                <div class="card-body">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h6 class="mb-0">
                        <b class="text-success">60%</b> Update in progress...
                      </h6>
                    </div>
                    <div class="flex-shrink-0">
                      <h6 class="mb-0">45s left</h6>
                    </div>
                  </div>
                </div>
                <div class="progress bg-soft-success rounded-0">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 60%"
                    aria-valuenow="60"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div class="card bg-light overflow-hidden shadow-none">
                <div class="card-body">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h6 class="mb-0">
                        <b class="text-danger">82%</b> Update in progress...
                      </h6>
                    </div>
                    <div class="flex-shrink-0">
                      <h6 class="mb-0">25s left</h6>
                    </div>
                  </div>
                </div>
                <div class="progress bg-soft-danger rounded-0">
                  <div
                    class="progress-bar bg-danger"
                    role="progressbar"
                    style="width: 82%"
                    aria-valuenow="82"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup" style="max-height:  275px">
<code>&lt;b-card class=&quot;bg-light overflow-hidden&quot;&gt;
    &lt;b-card no-body&gt;
        &lt;div class=&quot;d-flex&quot;&gt;
            &lt;div class=&quot;flex-grow-1&quot;&gt;
                &lt;h6 class=&quot;mb-0&quot;&gt;&lt;b class=&quot;text-secondary&quot;&gt;30%&lt;/b&gt; Update in progress...&lt;/h6&gt;
            &lt;/div&gt;
            &lt;div class=&quot;flex-shrink-0&quot;&gt;
                &lt;h6 class=&quot;mb-0&quot;&gt;1 min left&lt;/h6&gt;
            &lt;/div&gt;
        &lt;/div&gt;
    &lt;/b-card&gt;
&lt;b-progress :value=&quot;30&quot; class=&quot;custom-progress bg-soft-secondary rounded-0&quot; variant=&quot;secondary&quot;&gt;&lt;/b-progress&gt;
&lt;/b-card&gt;</code>

<code>&lt;b-card class=&quot;bg-light overflow-hidden&quot;&gt;
    &lt;b-card no-body&gt;
        &lt;div class=&quot;d-flex&quot;&gt;
            &lt;div class=&quot;flex-grow-1&quot;&gt;
                &lt;h6 class=&quot;mb-0&quot;&gt;&lt;b class=&quot;text-success&quot;&gt;60%&lt;/b&gt; Update in progress...&lt;/h6&gt;
            &lt;/div&gt;
            &lt;div class=&quot;flex-shrink-0&quot;&gt;
                &lt;h6 class=&quot;mb-0&quot;&gt;45s left&lt;/h6&gt;
            &lt;/div&gt;
        &lt;/div&gt;
    &lt;/b-card&gt;
&lt;b-progress :value=&quot;60&quot; class=&quot;custom-progress bg-soft-success rounded-0&quot; variant=&quot;success&quot;&gt;&lt;/b-progress&gt;
&lt;/b-card&gt;</code>

<code>&lt;b-card class=&quot;bg-light overflow-hidden&quot;&gt;
    &lt;b-card no-body&gt;
        &lt;div class=&quot;d-flex&quot;&gt;
            &lt;div class=&quot;flex-grow-1&quot;&gt;
                &lt;h6 class=&quot;mb-0&quot;&gt;&lt;b class=&quot;text-danger&quot;&gt;82%&lt;/b&gt; Update in progress...&lt;/h6&gt;
            &lt;/div&gt;
            &lt;div class=&quot;flex-shrink-0&quot;&gt;
                &lt;h6 class=&quot;mb-0&quot;&gt;25s left&lt;/h6&gt;
            &lt;/div&gt;
        &lt;/div&gt;
    &lt;/b-card&gt;
&lt;b-progress :value=&quot;82&quot; class=&quot;custom-progress bg-soft-danger rounded-0&quot; variant=&quot;danger&quot;&gt;&lt;/b-progress&gt;
&lt;/b-card&gt;</code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-xxl-6">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Progress with Steps</h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label
                  for="bsprogressexample-showcode"
                  class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="bsprogressexample-showcode"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted pb-2">
              Here is the example of progress which is represented by steps
              completion.
            </p>

            <div class="live-preview">
              <div class="position-relative m-4">
                <div class="progress" style="max-height:  1px">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: 50%"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <button
                  type="button"
                  class="
                    position-absolute
                    top-0
                    start-0
                    translate-middle
                    btn btn-sm btn-primary
                    rounded-pill
                  "
                  style="width: 2rem; height: 2rem"
                >
                  1
                </button>
                <button
                  type="button"
                  class="
                    position-absolute
                    top-0
                    start-50
                    translate-middle
                    btn btn-sm btn-primary
                    rounded-pill
                  "
                  style="width: 2rem; height: 2rem"
                >
                  2
                </button>
                <button
                  type="button"
                  class="
                    position-absolute
                    top-0
                    start-100
                    translate-middle
                    btn btn-sm btn-light
                    rounded-pill
                  "
                  style="width: 2rem; height: 2rem"
                >
                  3
                </button>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup">
<code>&lt;div class=&quot;position-relative m-4&quot;&gt;
    &lt;div class=&quot;progress&quot; style=&quot;height: 1px;&quot;&gt;
        &lt;div class=&quot;progress-bar&quot; role=&quot;progressbar&quot; style=&quot;width: 50%;&quot; aria-valuenow=&quot;25&quot; aria-valuemin=&quot;0&quot; aria-valuemax=&quot;100&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
    &lt;b-button size=&quot;sm&quot; variant=&quot;primary&quot; class=&quot;position-absolute top-0 start-0 translate-middle rounded-pill&quot; style=&quot;width: 2rem; height:2rem;&quot;&gt;1&lt;/b-button&gt;
    &lt;b-button size=&quot;sm&quot; variant=&quot;primary&quot; class=&quot;position-absolute top-0 start-50 translate-middle rounded-pill&quot; style=&quot;width: 2rem; height:2rem;&quot;&gt;2&lt;/b-button&gt;
    &lt;b-button size=&quot;sm&quot; variant=&quot;light&quot; class=&quot;position-absolute top-0 start-100 translate-middlerounded-pill&quot; style=&quot;width: 2rem; height:2rem;&quot;&gt;3&lt;/b-button&gt;
&lt;/div&gt;</code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">
              Step Progress with Arrow
            </h4>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
              >
                <label
                  for="stepprogressexample-showcode"
                  class="form-label text-muted"
                  >Show Code</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="stepprogressexample-showcode"
                />
              </div>
            </div>
          </div>
          <!-- end card header -->

          <div class="card-body">
            <p class="text-muted pb-2">
              Use
              <code>progress-step-arrow</code>class to create step progress with
              an arrow.
            </p>

            <div class="live-preview">
              <div class="progress progress-step-arrow progress-info">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 100%"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  Step 1
                </div>
                <div
                  class="progress-bar"
                  role="progressbar"
                  style="width: 100%"
                  aria-valuenow="30"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  Step 2
                </div>
                <div
                  class="progress-bar bg-light text-dark"
                  role="progressbar"
                  style="width: 100%"
                  aria-valuenow="20"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  Step 3
                </div>
              </div>
            </div>

            <div class="d-none code-view">
              <pre class="language-markup">
<code>
&lt;b-progress class=&quot;progress-step-arrow progress-info&quot;&gt;
    &lt;b-progress :value=&quot;33&quot; variant=&quot;primary&quot; &gt;Step 1 &lt;b-progress &gt;
    &lt;b-progress :value=&quot;33&quot; variant=&quot;primary&quot; &gt;Step 2 &lt;b-progress &gt;
    &lt;b-progress :value=&quot;33&quot; variant=&quot;light&quot; &gt;Step 3 &lt;b-progress &gt;
&lt;/b-progress&gt;
    </code></pre>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>